// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //WEB_URL: 'https://docshow.staginglabs.in', // Production URL
  //  API_URL: 'http://localhost:3011/api', //Local URL
//  API_URL: 'https://api.rdsports.in/api', // Production URL
//  WEB_URL: 'https://api.rdsports.in/', // Production URL

 API_URL: 'https://api.gemcoretechnologies.com/api', // Production URL
 WEB_URL: 'https://api.gemcoretechnologies.com/', // Production URL

  stripe_key: '',
  PUBLIC_VAPID_KEY: "BIwEbG10TyXM7dXiRuSPK7VGR3tOsith4jxGzp9U0pcGl5oh__PKVk2FT9JmXhZPRaI9aIFagQxXNs9SAEE-PKqNfbyiL_EwkQ"
};


/*
 * For easier de
bugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
//pawan@staginglabs.in
//@mywebsite007