import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../_services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class RedirectGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const user = this.authService.currentUser;
        if (user) {
            if (user.userType == 2 ) {
                this.router.navigate(['/provider']);
            } else if (user.userType == 99999) {
                this.router.navigate(['/patient']);
            }
            return true;
        }
        this.router.navigate(['/login']);
        return false;
    }
}