import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  isLoading = false;
  loaderCounter = 0;
  loading: any;

  constructor(public loadingController: LoadingController) { }

  async present() {
    // Dismiss all pending loaders before creating the new one
    // console.log(this.loading);
    
    if (this.loading)
      await this.dismiss();

    this.loading = await this.loadingController
      .create({ message: 'wait...', cssClass: 'activity-detail-loading'})
      .then(res => {
        res.present();
      });
  }

  /**
   * Dismiss all the pending loaders, if any
   */
  async dismiss() {
    while (await this.loadingController.getTop() !== undefined) {
      await this.loadingController.dismiss();
    }
  }
}