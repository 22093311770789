import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Platform, MenuController } from '@ionic/angular';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  templateUrl: './provider-layout.page.html',
  styleUrls: ['./provider-layout.page.scss']
})
export class ProviderLayoutPage {

  menus: any = [];
  currentUser: any;
  selectedMenu: any;

  constructor(
    private platform: Platform,
    private translate: TranslateService,
    private router: Router,
    private authService: AuthService,
    public menuController: MenuController
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.translate.setDefaultLang(event.lang);
        this.menus = event.translations.PROVIDERMENUS;
      });

      this.translate.get(['PROVIDERMENUS']).subscribe((data) => {
        this.menus = data.PROVIDERMENUS;
      });

      this.authService.onCurrentUserChange.subscribe(user => {
        this.currentUser = user;
      });
    });
  }

  gotoProfile() {
    this.router.navigate(['provider/profile']);
  }
  // gotoProviderAppointments() {
  //   this.router.navigate(['/provider/appointments']);
  // }
  gotoHome() {
    this.router.navigate(['/']);
  }
  signout() {
    this.menuController.close();
    this.authService.logout();
  }
  notification() {
    this.router.navigate(['provider/notification-list']);
  }

}
