/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ProviderLayoutPage } from './pages/shared/provider-layout/provider-layout.page';
import { AuthGuard } from './_helpers/auth.guard';
import { RedirectGuard } from './_helpers/redirect.guard';
import { PrivacyPolicyComponent } from './pages/shared/components/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './pages/shared/components/terms-and-conditions/terms-and-conditions.component';
import { FaqPage } from './pages/shared/components/faq-page/faq-page.component';
import { SupportPage } from './pages/shared/components/support/support.component';
import { OpenDescriptionPage } from './pages/provider/documents/open-description/open-description.page';

const routes: Routes = [
  { path: '', redirectTo: '', pathMatch: 'full', canActivate: [RedirectGuard] },

  {
    path: 'provider',
    component: ProviderLayoutPage,
    children: [
      { path: '', loadChildren: () => import('./pages/provider/tabs/tabs.module').then(m => m.ProviderTabsModule) },
      { path: 'change-password', loadChildren: () => import('./pages/common/change-password/change-password.module').then(m => m.ChangePasswordModule) },
      { path: 'edit-profile', loadChildren: () => import('./pages/provider/profile-edit/profile-edit.module').then(m => m.ProviderProfileEditModule) },

      { path: 'client-list', loadChildren: () => import('./pages/provider/clients/client-list/clientlist.module').then(m => m.ClientListModule) },
      { path: 'add-client', loadChildren: () => import('./pages/provider/clients/add-client/add-client.module').then(m => m.AddClientModule) },
       { path: 'edit-client/:id', loadChildren: () => import('./pages/provider/clients/edit-client/edit-client.module').then(m => m.EditClientModule) },
      { path: 'add-bank/:id', loadChildren: () => import('./pages/provider/clients/set-bank/set-bank.module').then(m => m.SetBankModule) },
      { path: 'bank-list/:id', loadChildren: () => import('./pages/provider/clients/banks/list.module').then(m => m.BankListModule) },
      { path: 'client-master-id/:id', loadChildren: () => import('./pages/provider/clients/client-master-id/list.module').then(m => m.ClientMasterIdModule) },

      { path: 'product-list', loadChildren: () => import('./pages/provider/Products/product-list/productlist.module').then(m => m.ProductListModule) },
      { path: 'add-product', loadChildren: () => import('./pages/provider/Products/product-list/add-product/add-product.module').then(m => m.AddProductModule) },
      { path: 'edit-product/:id', loadChildren: () => import('./pages/provider/Products/product-list/edit-product/edit-product.module').then(m => m.EditProductModule) },

      { path: 'our-banks', loadChildren: () => import('./pages/provider/OurBanks/banks/list.module').then(m => m.OurBanksListModule) },
      { path: 'create-bank', loadChildren: () => import('./pages/provider/OurBanks/banks/add-bank/add-bank.module').then(m => m.AddOurBankPageModule) },
      { path: 'edit-bank/:id', loadChildren: () => import('./pages/provider/Products/product-list/edit-product/edit-product.module').then(m => m.EditProductModule) },



      { path: 'master-list', loadChildren: () => import('./pages/provider/MasterId/masterid-list/masteridtlist.module').then(m => m.MasterIdListModule) },
      { path: 'generate-id', loadChildren: () => import('./pages/provider/MasterId/masterid-list/add-masterid/add-masterid.module').then(m => m.AddMaterIdModule) },
      { path: 'edit-master-id/:id', loadChildren: () => import('./pages/provider/MasterId/masterid-list/edit-masterid/edit-masterid.module').then(m => m.EditMasterIdModule) },


      { path: 'refill-list', loadChildren: () => import('./pages/provider/Refill/list/list.module').then(m => m.RefillListModule) },
      { path: 'refill', loadChildren: () => import('./pages/provider/Refill/add-refill/add-refill.module').then(m => m.AddRefillModule) },
      { path: 'edit-refill/:id', loadChildren: () => import('./pages/provider/Refill/edit-refill/edit-refill.module').then(m => m.EditRefillModule) },

      { path: 'withdrawl-list', loadChildren: () => import('./pages/provider/Withdrawl/list/list.module').then(m => m.WithdrawlListModule) },
      { path: 'withdraw', loadChildren: () => import('./pages/provider/Withdrawl/add-withdrawl/add-withdrawl.module').then(m => m.AddWithdrawlModule) },
      { path: 'update-payment-status/:id', loadChildren: () => import('./pages/provider/Withdrawl/edit-withdrawl/edit-withdrawl.module').then(m => m.EditWithdrawlModule) },

    ],
    canActivate: [AuthGuard], data: { userType: 2 }
  },

  { path: 'login', loadChildren: () => import('./pages/common/login/login.module').then(m => m.LoginModule) },
  // { path: 'register', loadChildren: () => import('./pages/common/register/register.module').then(m => m.RegisterModule) },
  // { path: 'register-doctor', loadChildren: () => import('./pages/common/register-doctor/register-doctor.module').then(m => m.RegisterDoctorModule) },
  // { path: 'confirm-email', loadChildren: () => import('./pages/common/confirm-email/confirm-email.module').then(m => m.ConfirmEmailModule)},
  // { path: 'privacy-policy', component: PrivacyPolicyComponent },
  // { path: 'faq', component: FaqPage },
  // { path: 'support', component: SupportPage },
  // { path: 'terms-conditions', component: TermsAndConditionsComponent },
  // { path: 'forgot-password', loadChildren: () => import('./pages/common/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule) },
  // { path: 'reset-password', loadChildren: () => import('./pages/common/reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

