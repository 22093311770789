import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  private _baseUrl = environment.API_URL;
  private _headers = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };


  constructor(
    private _http: HttpClient
  ) { }


  public getStatusString(status, userType = 1) {
    let _status;
    switch (status) {
      case 'pending':
        _status = 'Pending'
        break;
      case 'approved':
        _status = "Approved";
        break;
      case 'confirmed':
        _status = 'Confirmed'
        break;
      case 'arrived':
        _status = 'Arrived'
        break;
      case 'visitcomplete':
        _status = 'Visit Complete'
        break;
      case 'reschedule':
        _status = 'Re-Schedule'
        break;
      case 'npshow':
        _status = 'No-Show'
        break;
      case 'cancel':
        _status = 'Cancelled'
        break;
      default:
        _status = status
        break;
    }

    return _status;
  }

  // Get appointments
  public getPatientAppointments = payload => {
    let apiEndPoint = `/appointment/getpatientappointments`;
    return this._http.post<any>(this._baseUrl + apiEndPoint , payload, this._headers);
  }

  public getProviderAppointments = payload => {
    let apiEndPoint = '/appointment/getproviderappointments';
    return this._http.post<any>(this._baseUrl + apiEndPoint, payload, this._headers);
  }

 

  public getProviderAndPatientAppointments = params => {
    let apiEndPoint = `/appointment/providerandpatientppointments${params}`;
    return this._http.get<any>(this._baseUrl + apiEndPoint, this._headers);
  }

  // Get doctors list
  public getDoctors = (query) => {
    let apiEndPoint = '/doctors/search';
    return this._http.get<any>(this._baseUrl + apiEndPoint + query);
  }

  // Get doctors list
  public getDoctorsByPatient = (params) => {
    let apiEndPoint = `/doctors/searchbypatient${params}`;
    return this._http.get<any>(this._baseUrl + apiEndPoint, this._headers);
  }

  // Create new appointment for patient
  public createAppointment = payload => {
    let apiEndPoint = '/appointment/create';
    return this._http.post<any>(this._baseUrl + apiEndPoint, payload, this._headers);
  }

  public getDoctorSlots = payload => {
    let apiEndPoint = '/slots/getslots';
    return this._http.get<any>(this._baseUrl + apiEndPoint + payload, this._headers);
  }

  public cancelAppointment = payload => {
    let apiEndPoint = '/appointment/delete';
    return this._http.post<any>(this._baseUrl + apiEndPoint, payload, this._headers);
  }

  public getAppointmentDetail = payload => {
    let apiEndPoint = '/appointment/getappointment';
    return this._http.get<any>(this._baseUrl + apiEndPoint + "?appointment_id=" + payload, this._headers);
  }

  public updateAppointment = payload => {
    let apiEndPoint = '/appointment/updateDate';
    return this._http.post<any>(this._baseUrl + apiEndPoint, payload, this._headers);
  }

  public getAppointmentUnique = payload => {
    let apiEndPoint = '/appointment/unique';
    return this._http.post<any>(this._baseUrl + apiEndPoint, payload, this._headers);
  }

  public updateStatus = payload => {
    let apiEndPoint = '/appointment/update';
    return this._http.post<any>(this._baseUrl + apiEndPoint, payload, this._headers);
  }


  public getUnique = payload => {
    let apiEndPoint = '/appointment/unique';
    return this._http.post<any>(this._baseUrl + apiEndPoint, payload, this._headers);
  }

  public getSlots = payload => {
    let apiEndPoint = '/slots/getslots';
    return this._http.get<any>(this._baseUrl + apiEndPoint + '?doctor=' + payload.userId, this._headers);
  }

  public getSlotsNew = payload => {
    let apiEndPoint = '/slots/getslotsnew';
    return this._http.get<any>(this._baseUrl + apiEndPoint + '?doctor=' + payload.userId + '&date=' + payload.date, this._headers);
  }

  public createSlot = payload => {
    let apiEndPoint = '/slots/create';
    return this._http.post<any>(this._baseUrl + apiEndPoint, payload, this._headers);
  }

  public createNewSlot = payload => {
    let apiEndPoint = '/slots/createnew';
    return this._http.post<any>(this._baseUrl + apiEndPoint, payload, this._headers);
  }


  public deleteSlots = payload => {
    let apiEndPoint = '/slots/delete';
    return this._http.post<any>(this._baseUrl + apiEndPoint, payload, this._headers);
  }

  public getSpecialDay = payload => {
    let apiEndPoint = '/slots/getspcldates';
    return this._http.get<any>(this._baseUrl + apiEndPoint + payload, this._headers);
  }

  public addSpecialDayOff = payload => {
    let apiEndPoint = '/slots/addspcldate';
    return this._http.post<any>(this._baseUrl + apiEndPoint, payload, this._headers);
  }

  public getPatientsByProviderId = (params) => {
    let apiEndPoint = `/doctors/searchbyprovider${params}` 
    return this._http.get<any>(this._baseUrl + apiEndPoint, this._headers);
  }
}



